import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@/controllers';

document.addEventListener('DOMContentLoaded', function () {
  const editor = document.querySelector('.wysiwyg');
  if (editor !== null)
    ClassicEditor.create(editor, {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    }).catch((error) => {
      console.error(error);
    });
});
